// Custom types imports
import { TicketList } from "../../features/tickets/types";

// Custom componnets imports
import ContentWithToggle from "../shared/ContentWithToggle";

// Custom helpers imports
import { formatAmount } from "../../helpers";

// Types (@tanstack/react-query) imports
import { UseQueryResult } from "@tanstack/react-query";

interface Props {
  tickets: UseQueryResult<TicketList, unknown>;
}

function PaymentSummary({ tickets }: Props) {
  return (
    <div className="flex flex-col bg-white rounded-lg">
      <ContentWithToggle title="Resumen de los últimos 10 días" isToogle={true}>
        <div className="w-full flex flex-col divide-y mb-4 bg-white rounded-lg overflow-hidden">
          <div className="border-t w-full px-4 py-3 flex justify-between items-center">
            <span className="text-gray-600">Monto de transacciones</span>
            <div className="w-1/2 text-end">
              {!!tickets.isLoading && <b>Cargando...</b>}

              {!!tickets.isError && !tickets.data && <b>¡Hubo un error!</b>}

              {!!tickets.isSuccess && (
                <b>{formatAmount(tickets.data.invoicesData.totalAmount)}</b>
              )}
            </div>
          </div>
          <div className="border-t w-full px-4 py-3 flex justify-between items-center">
            <span className="text-gray-600">Número de transacciones</span>
            <div className="w-1/2 text-end">
              {!!tickets.isLoading && <b>Cargando...</b>}

              {!!tickets.isError && !tickets.data && <b>¡Hubo un error!</b>}

              {!!tickets.isSuccess && (
                <b>{tickets.data.invoicesData.totalItems}</b>
              )}
            </div>
          </div>
        </div>
      </ContentWithToggle>
    </div>
  );
}
export default PaymentSummary;
