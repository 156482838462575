// Hooks (@tanstack/react-query) imports
import { useMutation, useQueryClient } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { InvoiceRegistration } from "./types";
import { ApiErrorResponse } from "apisauce";

interface paramsType {
  invoice: InvoiceRegistration;
  notificationID: string | number;
}

export function useUpdateInvoiceMutation(
  onSuccessMutation?: (data: any, variables: paramsType) => void,
  onErrorMutation?: (err: ApiErrorResponse<any>, variables: paramsType) => void
) {
  const queryClient = useQueryClient();

  async function updateInvoice(params: paramsType): Promise<any> {
    const response = await api.put<any>(`invoices/invoices`, params.invoice);

    if (!response.ok) {
      throw response;
    }

    return response.data;
  }

  return useMutation({
    mutationFn: updateInvoice,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["Invoice", variables.invoice.id]);
      onSuccessMutation?.(data, variables);
    },
    onError: onErrorMutation,
  });
}
