import { PersonRegistration } from "../persons/types";

export enum invoiceStatus {
  pendient,
  bort,
  paid,
  refused,
  customerCanceled,
  storeCanceled,
}

export interface StatusData {
  description: string;
  color: string;
  textColor: string;
  icon: string;
}

export type StatusName =
  | "pendient"
  | "bort"
  | "paid"
  | "refused"
  | "customerCanceled"
  | "storeCanceled";

export type StatusIndex = 0 | 1 | 2 | 3 | 4 | 5;

export const invoiceStatusData: Record<StatusName, StatusData> = {
  pendient: {
    description: "Pendiente",
    color: "bg-gray-500",
    textColor: "text-gray-500",
    icon: "bi bi-info-circle-fill",
  },
  bort: {
    description: "Cobro suprimido",
    color: "bg-orange-500",
    textColor: "text-orange-500",
    icon: "bi bi-exclamation-circle-fill",
  },
  paid: {
    description: "Pago exitoso",
    color: "bg-green-500",
    textColor: "text-green-500",
    icon: "bi bi-check-circle-fill",
  },
  refused: {
    description: "Cobro rechazado por cliente",
    color: "bg-orange-500",
    textColor: "text-orange-500",
    icon: "bi bi-exclamation-circle-fill",
  },
  customerCanceled: {
    description: "Pago anulado por cliente",
    color: "bg-red-500",
    textColor: "text-red-500",
    icon: "bi bi-x-circle-fill",
  },
  storeCanceled: {
    description: "Pago anulado por establecimiento",
    color: "bg-red-500",
    textColor: "text-red-500",
    icon: "bi bi-x-circle-fill",
  },
};

export enum invoiceSource {
  store = 0,
  corporation = 1,
  customer = 2,
}

export interface InvoiceByModel {
  maxItems: number;
  modifiedAfter: string;
  expiredBy: string;
  vStatus: number;
  storeID: number;
  idCard: string;
  amount: number;
  invoiceStatus: number;
  date?: string;
  accessCode: string;
}

export interface InvoiceRegistration {
  id: number;
  invoiceID: number;
  invoiceType: number;
  storeID: number;
  idCard: string;
  amount: number;
  iva: number;
  invoiceStatus: StatusIndex;
  date: string;
  storeInvoice: string;
  invoiceSource: number;
  description: string;
  accessCode?: string;
  paymentMethod?: number;
}

export interface Invoice extends InvoiceRegistration {
  minDate: string;
  subTotal: number;
  commission: number;
  ivaCommission: number;
  reteICA: number;
  reteIVA: number;
  reteFuente: number;
  collectionAmount: number;
}

export type FormDataCreateInvoice = {
  invoice: InvoiceRegistration;
  person: PersonRegistration;
};

export type FormDataEditInvoice = {
  invoice: Invoice;
  person: PersonRegistration;
};

export const defaultValues: FormDataCreateInvoice = {
  invoice: {
    id: 0,
    invoiceID: 0,
    invoiceType: 0,
    storeID: 0,
    idCard: "",
    amount: 0,
    iva: 0,
    invoiceStatus: 0,
    date: new Date().toISOString(),
    storeInvoice: "",
    invoiceSource: 0,
    description: "",
    accessCode: "",
  },
  person: {
    maxItems: 0,
    modifiedAfter: new Date().toISOString(),
    expiredBy: new Date().toISOString(),
    vStatus: 0,
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    idCard: "",
    idCardType: 1,
    idCardValue: "",
    phoneNumber: "",
    phoneCountry: 170,
    phoneNumberValue: "",
    birthCountryID: 170,
    birthDate: new Date().toISOString(),
    name: "",
    passwordToken: true,
  },
};

export const fetchingModelDefaults: InvoiceByModel = {
  maxItems: 0,
  modifiedAfter: new Date().toISOString(),
  expiredBy: new Date().toISOString(),
  vStatus: 0,
  storeID: 0,
  idCard: "",
  amount: 0,
  invoiceStatus: 0,
  accessCode: "",
};
